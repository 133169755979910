// routes
import {
  PATH_DASHBOARD,
  PATH_ACCOUNT,
  PATH_SETTINGS,
  PATH_TEAM,
  PATH_INVESTMENT,
} from '../../../paths';
// components
import Iconify from '../../../components/Iconify';

const navConfig = [
  {
    subheader: 'General',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.app,
        icon: <Iconify icon="bxs:dashboard" />,
      },
    ],
  },
  {
    subheader: 'Investment Management',
    items: [
      {
        title: 'Investment',
        path: PATH_INVESTMENT.root,
        icon: <Iconify icon="ant-design:stock-outlined" />,
        children: [
          { title: 'Discover', path: PATH_INVESTMENT.discover },
          { title: 'Invested portfolios', path: PATH_INVESTMENT.portfolios },
        ],
      },
    ],
  },
  {
    subheader: 'Account Management',
    items: [
      {
        title: 'Accounts',
        path: PATH_ACCOUNT.root,
        icon: <Iconify icon="mdi:bank" />,
        children: [
          { title: 'Transaction', path: PATH_ACCOUNT.transaction },
          { title: 'Transfer', path: PATH_ACCOUNT.transfer },
          { title: 'Convert', path: PATH_ACCOUNT.convert },
          { title: 'Withdrawal', path: PATH_ACCOUNT.withdrawal },
        ],
      },
    ],
  },
  {
    subheader: 'Organization',
    items: [
      {
        title: 'Teams',
        path: PATH_TEAM.root,
        icon: <Iconify icon="codicon:organization" />,
      },
    ],
  },
  {
    subheader: 'Options',
    items: [
      {
        title: 'Settings',
        path: PATH_SETTINGS.root,
        icon: <Iconify icon="ci:settings-filled" />,
      },
    ],
  },
];

export default navConfig;
