import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Stack, Link, Container, Typography } from '@mui/material';

import useResponsive from '../../hooks/useResponsive';
import useLocales from '../../hooks/useLocales';

import { PATH_AUTH } from '../../paths';

import Page from '../../components/Page';
import Logo from '../../components/Logo';
import LanguagePopover from '../../layouts/dashboard/header/LanguagePopover';
import { LoginForm } from '../../sections/auth/login';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const { translate } = useLocales();

  const smUp = useResponsive('up', 'sm');

  return (
    <Page title={translate('Login')}>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {translate('Don’t have an account?')} {''}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                {translate('Get started')}
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translate('Sign in to BERILLIUM')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {translate('Enter your details below.')}
                </Typography>
              </Box>
              <LanguagePopover />
            </Stack>

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {translate('Don’t have an account?')}{' '}
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={PATH_AUTH.register}
                >
                  {translate('Get started')}
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
