import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enLocales from './en.json';
import zhCNLocales from './zh-cn.json';
// import jpLocales from './jp.json';
// import koLocales from './ko.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      zhCN: { translations: zhCNLocales },
      en: { translations: enLocales },
      // jp: { translations: jpLocales },
      // ko: { translations: koLocales },
    },
    lng: localStorage.getItem('i18nextLng') || 'zhCN',
    fallbackLng: 'zhCN',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
